import React from "react"
import PropTypes from "prop-types"
import Container from "../components/Container"
import { useGetLinks } from "../helpers"

const SiteHeader = ({location, isHome}) => {
  return (
    <>
      <header className="mikeb-header is-sticky" role="banner">
        <Container>
          <nav>{useGetLinks("desktop", "site", location)}</nav>
        </Container>
      </header>
    </>
  )
}

SiteHeader.defaultProps = {
  location : null,
}

SiteHeader.propTypes = {
  location : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default SiteHeader
