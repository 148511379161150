import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

const SiteLink = ({href, onClick, children, ...props}) => {
  const onClickLocal = () => {
    if ( onClick ) {
      onClick()
    }
  }

  return (
    <Link to={href} onClick={onClickLocal} {...props}>{children}</Link>
  )
}

SiteLink.propTypes = {
  href     : PropTypes.string,
  children : PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.array, PropTypes.object]),
}

export default SiteLink
