import React from "react"
import PropTypes from "prop-types"
import Container from "../components/Container"
import Svg from "../components/Svg"

const Accordion = ({data, containerSize, alohaStart, className, children, ...props}) => {

  const AccordionItemContent = ({theData, containerSize}) => {
    return (
      <>
        <summary>
          <Container size={containerSize}>
            <Svg type="caret"/>
            <span>{theData.summary}</span>
          </Container>
        </summary>
        <Container size={containerSize}>
          <div className="mikeb-accordion-details-content">
            <div className="mikeb-accordion-details-contnt-inner">{theData.details}</div>
          </div>
        </Container>
      </>
    )
  } 

  const getAlohaStyle = (i, alohaStart) => {
    if ( alohaStart === null ) {
      return null
    }
    return { "--aloha-step" : i + parseInt(alohaStart) }
  }

  return (
    <div className="mikeb-accordion" {...props}>
      {data && data.map((theData, i) => {
        return (
          <details key={`mikeb-accordion-item-${i}`} style={getAlohaStyle(i, alohaStart)}>
            <AccordionItemContent theData={theData} containerSize={containerSize}/>
          </details>
        )
      })}
    </div>
  )

}

Accordion.defaultProps = {
  data          : null,
  containerSize : "xl",
  alohaStart    : null,
  className     : '',
  children      : null,
}

Accordion.propTypes = {
  data          : PropTypes.array,
  containerSize : PropTypes.string,
  alohaStart    : PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className     : PropTypes.string,
  children      : PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
}

export default Accordion
