import React from "react"
import PropTypes from "prop-types"

const CalendlyLink = ({href, label, className, ...props}) => {
  return (
    <a
      href      = {href}
      className = {`mikeb-calendly-link${className && ` ${className}`}`}
      target    = "_blank"
      rel       = "noreferrer"
      {...props}
    >
      <span>{label}</span>
    </a>
  )
}

CalendlyLink.defaultProps = {
  href      : "https://calendly.com/mbream-1/30min",
  label     : "Schedule a Meeting",
  className : null,
}

CalendlyLink.propTypes = {
  href      : PropTypes.string,
  label     : PropTypes.string,
  className : PropTypes.string,
}

export default CalendlyLink
