import React from "react"
import Container from "../components/Container"

const SiteFooter = ({location, isHome}) => {
  return (
    <footer className="mikeb-footer" role="contentinfo">
      <Container>
        <p>Mike Bream &mdash; Weddings &amp; Special Events &mdash;&nbsp;Dallas&nbsp;/&nbsp;Fort&nbsp;Worth</p>
      </Container>
    </footer>
  )
}

export default SiteFooter
