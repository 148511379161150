import React from "react"
import PropTypes from "prop-types"
import SiteFooter from "../components/SiteFooter"
import SiteHeader from "../components/SiteHeader"

const Site = ({location, slug, children}) => {
  const homePath = `${__PATH_PREFIX__}/`
  const isHome   = location.pathname === homePath

  return (
    <div className={`mikeb-root is-${slug}`} data-is-home={isHome}>
      <SiteHeader location={location} isHome={isHome}/>
      <main className={`mikeb-body is-${slug}`}>{children}</main>
      <SiteFooter location={location} isHome={isHome}/>
    </div>
  )
}

Site.defaultProps = {
  location : null,
  slug     : "slug",
  children : null,
}

Site.propTypes = {
  location : PropTypes.object,
  slug     : PropTypes.string,
  children : PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
}

export default Site
