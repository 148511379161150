import React from "react"
import PropTypes from "prop-types"

const Section = ({tag, className, children, ...props}) => {

  return (
    <section className={`mikeb-section${className && ` ${className}`}`} {...props}>
      {children}
    </section>
  )

}

Section.defaultProps = {
  className : '',
  children  : null,
}

Section.propTypes = {
  className : PropTypes.string,
  children  : PropTypes.oneOfType([PropTypes.string, PropTypes.array, PropTypes.object]),
}

export default Section
