import React from "react"
import PropTypes from "prop-types"

const Svg = ({type, ...props}) => {

  const data = {

    darr : {
      slug    : 'arrow-down',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M4.293,15.707l7,7a1,1,0,0,0,1.414,0l7-7a1,1,0,0,0-1.414-1.414L13,19.586V2a1,1,0,0,0-2,0V19.586L5.707,14.293a1,1,0,0,0-1.414,1.414Z"/></g>,
    },
    rarr : {
      slug    : 'arrow-right',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M22.707,11.293L15,3.586L13.586,5l6,6H2c-0.553,0-1,0.448-1,1s0.447,1,1,1h17.586l-6,6L15,20.414 l7.707-7.707C23.098,12.316,23.098,11.684,22.707,11.293z"/></g>,
    },
    larr : {
      slug    : 'arrow-left',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M1.293,11.293L9,3.586L10.414,5l-6,6H22c0.553,0,1,0.448,1,1s-0.447,1-1,1H4.414l6,6L9,20.414l-7.707-7.707 C0.902,12.316,0.902,11.684,1.293,11.293z"/></g>,
    },
    nearr : {
      slug    : 'fullscreen-double-74',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M22,1h-9v2h6.6l-10,10l1.4,1.4l10-10V11h2V2C23,1.4,22.6,1,22,1z"/></g>,
    },
    caret : {
      slug    : 'triangle-right',
      width   : '24',
      height  : '24',
      viewbox : '0 0 24 24',
      markup  : <g><path d="M7.6,2.2A1,1,0,0,0,6,3V21a1,1,0,0,0,1.6.8l12-9a1,1,0,0,0,0-1.6Z"/></g>,
    },

  }

  return (
    <svg
      className = {`mikeb-svg is-${type}`}
      width     = {`${data[type].width}`}
      height    = {`${data[type].height}`}
      viewBox   = {`${data[type].viewbox}`}
      xmlns     = "http://www.w3.org/2000/svg"
      {...props}
    >
      {data[type].markup}
    </svg>
  )

}

Svg.defaultProps = {
  type : 'menu',
}

Svg.propTypes = {
  type : PropTypes.string,
}

export default Svg
