import React from "react"
import PropTypes from "prop-types"

const Metadata = ({ title, titleDelimiter, titleSuffix, description, url, image, themeColor }) => {
  const theOrigin = process.env.GATSBY_ORIGIN
  const theTitle  = title === null ? titleSuffix : `${title}${titleDelimiter}${titleSuffix}`

  return (
    <>
      <title>{theTitle}</title>

      <meta name="description" content={description}/>
      <meta name="viewport" content="width=device-width, initial-scale=1"/>
      <meta name="format-detection" content="telephone=no"/>

      <meta property="og:type" content="website"/>
      <meta property="og:site_name" content="Mike Bream"/>
      <meta property="og:url" content={url}/>
      <meta property="og:title" content={theTitle}/>
      <meta property="og:description" content={description}/>
      <meta property="og:image" content={`${theOrigin}${image}`}/>

      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:url" content={url}/>
      <meta property="twitter:title" content={theTitle}/>
      <meta property="twitter:description" content={description}/>
      <meta property="twitter:image" content={`${theOrigin}${image}`}/>

      <link rel="apple-touch-icon" sizes="180x180" href={`${theOrigin}/social/apple-touch-icon.png`}/>
      <link rel="icon" type="image/png" sizes="32x32" href={`${theOrigin}/social/favicon-32x32.png`}/>
      <link rel="icon" type="image/png" sizes="16x16" href={`${theOrigin}/social/favicon-16x16.png`}/>
      <link rel="manifest" href={`${theOrigin}/social/site.webmanifest`}/>
      <link rel="mask-icon" href={`${theOrigin}/social/safari-pinned-tab.svg" color="#5bbad5`}/>
      <link rel="shortcut icon" href={`${theOrigin}/social/favicon.ico`}/>
      <meta name="msapplication-TileColor" content="#18181b"/>
      <meta name="msapplication-config" content={`${theOrigin}/social/browserconfig.xml`}/>
      <meta name="theme-color" content={themeColor}/>
    </>
  )
}

Metadata.defaultProps = {
  title          : null,
  titleDelimiter : " — ",
  titleSuffix    : "Mike Bream",
  description    : "Weddings & Special Events in the Dallas / Fort Worth Metroplex",
  url            : "https://mikebream.com/",
  image          : "/social/og.jpg",
  themeColor     : "#18181b",
}

Metadata.propTypes = {
  title          : PropTypes.string,
  titleDelimiter : PropTypes.string,
  titleSuffix    : PropTypes.string,
  description    : PropTypes.string,
  url            : PropTypes.string,
  image          : PropTypes.string,
  themeColor     : PropTypes.string,
}

export default Metadata
