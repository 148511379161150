// =============================================================================
// HELPERS.JS
// -----------------------------------------------------------------------------
// I get by with a little help from my friends...
// =============================================================================

// =============================================================================
// TABLE OF CONTENTS
// -----------------------------------------------------------------------------
//   01. Imports
//   02. useGetLinks()
// =============================================================================

// Imports
// =============================================================================

import React from "react"
import SiteLink from "./components/SiteLink"



// useGetLinks()
// =============================================================================

export function useGetLinks(bp = "desktop", type = "site", location) {
  const dataSiteLinks = [
    { href : "#about",   label : "About"   },
    { href : "#faq",     label : "FAQ"     },
    { href : "#contact", label : "Contact" },
  ]

  const dataSocialLinks = [
    { href : "https://instagram.com/#", label : "IG" },
    { href : "https://youtube.com/#",   label : "YT" },
  ]

  let theLinks  = []
  let theData   = type === "site" ? dataSiteLinks : dataSocialLinks
  let Tag       = type === "site" ? SiteLink : "a"
  let iOffset   = type === "site" ? 1 : 1 + dataSiteLinks.length
  let theTarget = type === "site" ? null : "_blank"

  theData.map((el, i) => {
    return theLinks.push(
      <Tag
        key       = {`${bp}-${type}-${el.href}`}
        style     = {{'--aloha-step' : i + iOffset}}
        className = {location.pathname === el.href ? "is-current" : null}
        href      = {el.href}
        target    = {theTarget}
      >
        <span>{el.label}</span>
      </Tag>
    )
  })

  return theLinks
}
