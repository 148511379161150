import * as React from "react"
import Accordion from "../components/Accordion"
import CalendlyLink from "../components/CalendlyLink"
import Container from "../components/Container"
import Metadata from "../components/Metadata"
import Section from "../components/Section"
import Site from "../components/Site"
import Svg from "../components/Svg"

export const Head = () => <Metadata
  title       = "Mike Bream"
  titleSuffix = "Weddings & Special Events in the Dallas / Fort Worth Metroplex"
/>

const PageHome = ({data, location}) => {
  return (
    <Site location={location} slug="index">



      <Section className="is-intro">
        <Container>
          <div className="is-content">
            <h1>
              <span style={{ "--aloha-step" : "1" }}>Mike</span>
              <span style={{ "--aloha-step" : "2" }}><b>Bream</b></span>
            </h1>
            <p>
              <span style={{ "--aloha-step" : "3" }}>Weddings &amp; Special Events</span>
              <span style={{ "--aloha-step" : "4" }}>Dallas / Fort Worth</span>
            </p>
          </div>
          <div className="is-gallery-image is-01"><img style={{ "--aloha-step" : "5" }} src="/gallery-03.jpg" alt="Mike Bream Officiating a Wedding"/></div>
          <div className="is-gallery-image is-02"><img style={{ "--aloha-step" : "6" }} src="/gallery-05.jpg" alt="Mike Bream Officiating a Wedding"/></div>
          <div className="is-gallery-image is-03"><img style={{ "--aloha-step" : "7" }} src="/gallery-02.jpg" alt="Mike Bream Officiating a Wedding"/></div>
          <div className="is-gallery-image is-04"><img style={{ "--aloha-step" : "8" }} src="/gallery-06.jpg" alt="Mike Bream Officiating a Wedding"/></div>
        </Container>
        <Svg type="darr"/>
      </Section>



      <Section id="about" className="is-about">
        <Container size="md">
          <h2>
            <span style={{ "--aloha-step" : "1" }}>Hi<span className="kern-comma">,</span> I<span className="kern-apos">&rsquo;</span>m</span>
            <span style={{ "--aloha-step" : "2" }}><b>Mike</b></span>
          </h2>
          <img style={{ "--aloha-step" : "3" }} src="/gallery-03.jpg" alt="Mike Bream"/>
          <div className="is-content">
            <p style={{ "--aloha-step" : "4" }}>I&apos;ve been a non-denominational pastor in the Dallas / Fort Worth Metroplex since 2010 and in 2014 I officiated my first wedding. Every year since then, I have had the honor and privilege to support couples prior to and during their wedding day. I want to listen, collaborate, and meet the needs of each individual couple so that your ceremony is authentic, meaningful, and&nbsp;memorable.</p>
          </div>
        </Container>
      </Section>



      <Section id="faq" className="is-faq">
        <Container size="sm">
          <h2>
            <span style={{ "--aloha-step" : "1" }}>Frequently</span>
            <span style={{ "--aloha-step" : "2" }}><b>Asked</b></span>
          </h2>
        </Container>
        <Accordion
          alohaStart    = "3"
          containerSize = "sm"
          data          = {[
          {
            summary : "What is your rate?",
            details : <p>For local weddings in the Dallas / Fort Worth area, officiating weddings costs $350. I am also available to facilitate rehearsal dinners for an additional $150. To learn more, <CalendlyLink label="schedule a kickoff call"/> for us to meet and I am happy to answer any additional questions.</p>,
          },
          {
            summary : "Do you officiate destination weddings?",
            details : <p>Yes! I have been a part of numerous destination weddings and am happy to discsuss availability and logistics once you <CalendlyLink label="schedule a kickoff call"/>.</p>,
          },
          {
            summary : "What does your process look like?",
            details : <p>First, <CalendlyLink label="schedule a kickoff call"/>. This usually lasts about 30 minutes and is typically done on Zoom (or we can do a phone call if that is easier). We will discuss availability, specifics about your big day, and craft every detail to be tailor-made for you as we continue to work together. 45 days out from the wedding, we will touch base to make any potential adjustments if needed.</p>,
          },
          {
            summary : "Can I reach out to you to talk in person?",
            details : <p>Absolutely! To begin the planning process, <CalendlyLink label="schedule a kickoff call"/> with me to set up an initial meeting. During that time we will discuss availability, details about your wedding, and exchange contact information.</p>,
          },
        ]}/>
      </Section>



      <Section id="contact" className="is-contact">
        <Container size="sm">
          <h2>
            <span style={{ "--aloha-step" : "1" }}>Let<span className="kern-apos">&rsquo;</span>s</span>
            <span style={{ "--aloha-step" : "2" }}><b>Talk</b></span>
          </h2>
          <p style={{ "--aloha-step" : "3" }}>Schedule a call with me to begin planning your big day.</p>
          <CalendlyLink style={{ "--aloha-step" : "4" }} label="Schedule Kickoff Call"/>
        </Container>
      </Section>



    </Site>
  )
}

export default PageHome
